import { Routes } from '@angular/router';
import { UmbracoConfigGuard } from 'app/auth/umbraco-config-guard';
import { MaintanceErrorComponent } from 'app/containers/error-page/maintance-error/maintance-error';
import { MaintenanceGuard } from './auth/maintenance.guard';
var ɵ0 = function () { return import("./containers/layout/layout.module.ngfactory").then(function (m) { return m.LayoutModuleNgFactory; }); };
var appRoutes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', loadChildren: ɵ0, canActivate: [UmbracoConfigGuard] },
    { path: 'maintance-error', component: MaintanceErrorComponent, canActivate: [MaintenanceGuard] },
    { path: '**', redirectTo: 'app' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
