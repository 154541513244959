import { MessageModalService } from '../components/message-modal/message-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterWrapper } from '../../core/routing/router.wrapper';
import { AuthService } from '../../auth/auth.service';
import { ScreenGeneral } from '../utils/screen-general';
import * as i0 from "@angular/core";
import * as i1 from "../components/message-modal/message-modal.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../core/routing/router.wrapper";
import * as i4 from "../../auth/auth.service";
import * as i5 from "../utils/screen-general";
var ErrorHandlerService = /** @class */ (function () {
    function ErrorHandlerService(messageModalService, translateService, routerWrapper, authService, screenGeneral) {
        var _this = this;
        this.messageModalService = messageModalService;
        this.translateService = translateService;
        this.routerWrapper = routerWrapper;
        this.authService = authService;
        this.screenGeneral = screenGeneral;
        this.specificErrorsByCode = {
            'CA-0002': {
                title: 'inactive-user',
                handle: function () { return _this.handleInactiveUserError(); }
            },
            'CA-0020': {
                handle: function () {
                }
            },
            'CA-0021': {
                handle: function () {
                }
            },
            'CST-005': {
                handle: function () { return _this.handleNotEditablePDFRevision(); }
            },
        };
        this.endpointsToSkip = ['/getbanners', '/setbannerview', '/setbannerstopshow', '/setbannerapproval', '/local-news'];
    }
    ErrorHandlerService.prototype.handleErrorByCode = function (error) {
        this.specificErrorsByCode[error.returnCode] ?
            this.handleSpecificErrorByCode(error) : this.handleGeneralErrorByCode(error);
    };
    ErrorHandlerService.prototype.handleInactiveUserError = function () {
        this.authService.clearAuthorizationData();
        this.routerWrapper
            .toErrorPage({ text: 'Your account is currently inactive. Please contact your local administrator.', params: {} }, true);
    };
    ErrorHandlerService.prototype.handleUnknownError = function (error, url) {
        if (!this.isEndpointToSkip(url)) {
            this.screenGeneral.screenGeneralError();
        }
    };
    ErrorHandlerService.prototype.isEndpointToSkip = function (url) {
        for (var _i = 0, _a = this.endpointsToSkip; _i < _a.length; _i++) {
            var endpoint = _a[_i];
            if (url.includes(endpoint)) {
                return true;
            }
        }
        return false;
    };
    ErrorHandlerService.prototype.handleGeneralErrorByCode = function (error) {
        var _this = this;
        var desc = error.returnMessage.split('.')[0].split(' ').map(function (v) { return _this.capitalizeFirstLetter(v); }).join('').slice(0, 20);
        var key = "Errors.ByCode." + desc + "_" + error.returnCode;
        this.messageModalService.callModal(this.translateService.instant(key), this.translateService.instant('Errors.General.GeneralTitle'));
    };
    ErrorHandlerService.prototype.handleSpecificErrorByCode = function (error) {
        this.specificErrorsByCode[error.returnCode].handle(error);
    };
    ErrorHandlerService.prototype.capitalizeFirstLetter = function (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };
    ErrorHandlerService.prototype.handleNotEditablePDFRevision = function () {
        this.messageModalService.callModal(this.translateService.instant('Labels.PDFNotEditableVersionMsg'), this.translateService.instant('Errors.General.GeneralTitle'));
    };
    ErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.ɵɵinject(i1.MessageModalService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.RouterWrapper), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.ScreenGeneral)); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
