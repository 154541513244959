var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService, MsalAngularConfiguration, MsalGuard, MsalService } from '@azure/msal-angular';
import { Location, PlatformLocation } from '@angular/common';
import { MobileService } from '../shared/services/mobile.service';
var CustomMsalGuard = /** @class */ (function (_super) {
    __extends(CustomMsalGuard, _super);
    function CustomMsalGuard(msalConfig, msalAngularConfig, router, activatedRoute, location, platformLocation, broadcastService, msalService, mobileService) {
        var _this = _super.call(this, msalConfig, msalAngularConfig, msalService, router, activatedRoute, location, platformLocation, broadcastService) || this;
        _this.msalService = msalService;
        _this.mobileService = mobileService;
        return _this;
    }
    CustomMsalGuard.prototype.canActivate = function (route, state) {
        if (this.mobileService.isApple() || this.mobileService.isIE()) {
            this.msalService.getLogger().verbose('location change event from old url to new url');
            if (!this.msalService.getAccount()) {
                this.msalService.loginRedirect();
                return false;
            }
            return true;
        }
        else {
            return _super.prototype.canActivate.call(this, route, state);
        }
    };
    return CustomMsalGuard;
}(MsalGuard));
export { CustomMsalGuard };
