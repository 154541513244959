import { BehaviorSubject } from 'rxjs';
var SharedDataService = /** @class */ (function () {
    function SharedDataService() {
        this.sharedData = {};
        this.headerTitle = new BehaviorSubject('');
    }
    SharedDataService.prototype.getMyShipData = function () {
        return this.sharedData['shipData'];
    };
    SharedDataService.prototype.setMyShipData = function (data) {
        this.sharedData['shipData'] = data;
    };
    SharedDataService.prototype.getDeliveryData = function () {
        return this.sharedData['deliveryData'];
    };
    SharedDataService.prototype.setDeliveryData = function (data) {
        this.sharedData['deliveryData'] = data;
    };
    SharedDataService.prototype.getZimMonitorDashData = function () {
        return this.sharedData['zimMonitorDashData'];
    };
    SharedDataService.prototype.setZimMonitorDashData = function (data) {
        this.sharedData['zimMonitorDashData'] = data;
    };
    SharedDataService.prototype.getZimMonitorBoxFilter = function () {
        return this.sharedData['zimMonitorBoxFilter'];
    };
    SharedDataService.prototype.setZimMonitorBoxFilter = function (data) {
        this.sharedData['zimMonitorBoxFilter'] = data;
    };
    SharedDataService.prototype.setDeliveryOrderTempBLNo = function (data) {
        this.sharedData['deliveryOrderTempBLNo'] = data;
    };
    SharedDataService.prototype.getDeliveryOrderTempBLNo = function () {
        return this.sharedData['deliveryOrderTempBLNo'];
    };
    SharedDataService.prototype.setBookingNumber = function (data) {
        this.sharedData['bookingTempBLNo'] = data;
    };
    SharedDataService.prototype.getBookingNumber = function () {
        return this.sharedData['bookingTempBLNo'];
    };
    SharedDataService.prototype.setHeaderTitle = function (data) {
        this.headerTitle.next(data);
    };
    SharedDataService.prototype.getHeaderTitle = function () {
        return this.headerTitle.asObservable();
    };
    return SharedDataService;
}());
export { SharedDataService };
