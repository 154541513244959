import { HttpClientService } from 'app/core/http/http-client.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { JwtHelper } from 'app/shared/helpers/JWTParser';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { MobileService } from '../shared/services/mobile.service';
import { Logger } from 'msal';
import { CryptoUtils } from 'msal/lib-es6';
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, environmentConfig, broadcastService, routerWrapper, cookieService, msalService, mobileService) {
        var _this = this;
        this.httpClient = httpClient;
        this.environmentConfig = environmentConfig;
        this.broadcastService = broadcastService;
        this.routerWrapper = routerWrapper;
        this.cookieService = cookieService;
        this.msalService = msalService;
        this.mobileService = mobileService;
        this.authState = new Subject();
        this.envType = 'zim';
        this.jwtHelper = new JwtHelper();
        this.baseApi = environmentConfig.get('baseApiUrl');
        this.envType = this.environmentConfig.get('type').toLowerCase();
        this.msalService.handleRedirectCallback(function (authError, response) {
            if (_this.mobileService.isApple() || _this.mobileService.isIE()) {
                _this.setTokenFromMsalData();
            }
            var envConf = window['caEnvironmentConfig'];
            var msalErrorDescription = localStorage.getItem('msal.error.description');
            if (msalErrorDescription) {
                if (msalErrorDescription.indexOf('AADB2C90118') > -1) {
                    window.location.href = envConf.signIn.forgotPasswordLink;
                    return;
                }
            }
        });
        this.broadcastService.subscribe('msal:acquireTokenSuccess', function (payload) {
            if (payload.accessToken) {
                _this.cookieService.put('zim_access_token', payload.accessToken);
            }
        });
    }
    AuthService.prototype.authInit = function () {
        var _this = this;
        if (this.environmentConfig.get('enableMSALLogger')) {
            this.msalService.setLogger(new Logger(function (logLevel, message, piiEnabled) {
                console.log('MSAL Logging: ', message);
            }, {
                correlationId: CryptoUtils.createNewGuid(),
                piiLoggingEnabled: false
            }));
        }
        if (!!this.msalService.getAccount()) {
            this.checkErrors();
            this.getAuthData().subscribe(function (data) {
                if (data.contact == null || data.dsaData == null) {
                    _this.routerWrapper.toLogin();
                    return;
                }
                _this.cookieService.put('language', data.contact.language);
                _this.setAuthData(data);
                if (_this.envType !== 'zim') {
                    _this.routerWrapper.toPrint();
                }
            });
        }
        else {
            this.msalService.loginRedirect();
        }
    };
    AuthService.prototype.getAuthState = function () {
        return this.authState;
    };
    AuthService.prototype.setAuthState = function (auth) {
        this.authState.next(auth);
    };
    AuthService.prototype.isAuthentificated = function () {
        return !!this.msalService.getAccount();
    };
    AuthService.prototype.getAuthData = function () {
        return this.httpClient.get(this.baseApi + this.environmentConfig.get('getmycontactEndPoint').contacts);
    };
    AuthService.prototype.setAuthData = function (data) {
        sessionStorage.setItem('zim_auth_data', JSON.stringify(data));
        this.setAuthState(true);
    };
    AuthService.prototype.removeAuthData = function () {
        sessionStorage.removeItem('zim_auth_data');
        this.setAuthState(false);
    };
    AuthService.prototype.checkTokenDsa = function (token) {
        var parsedObj = this.jwtHelper.decodeToken(token);
        return parsedObj && parsedObj['extension_DSAContactId'] !== '';
    };
    AuthService.prototype.logout = function () {
        this.clearAuthorizationData();
        this.routerWrapper.toLogout();
    };
    AuthService.prototype.clearAuthorizationData = function () {
        this.cookieService.remove('zim_access_token');
        this.cookieService.remove('refresh_token');
        this.cookieService.remove('zim_customer_services');
        this.cookieService.remove('expires_in');
        this.removeAuthData();
    };
    AuthService.prototype.checkErrors = function () {
        if (window.location.href.includes('error_description=AADB2C90118')) {
            window.location.href = this.environmentConfig.get('signIn').forgotPasswordLink;
            return;
        }
        else if (window.location.href.includes('error_description=AADB2C90091')) {
            this.msalService.loginRedirect();
            return;
        }
        else if (window.location.href.includes('error_description=AADB2C90075')) {
            this.routerWrapper.toErrorPage();
            return;
        }
    };
    AuthService.prototype.setTokenFromMsalData = function () {
        for (var _i = 0, _a = Object.keys(localStorage); _i < _a.length; _i++) {
            var key = _a[_i];
            try {
                var parsedKey = JSON.parse(key);
                if (typeof parsedKey === 'object' && parsedKey.authority && parsedKey.clientId && parsedKey.homeAccountIdentifier) {
                    var data = JSON.parse(localStorage.getItem(key));
                    this.cookieService.put('zim_access_token', data.accessToken);
                    return;
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    };
    return AuthService;
}());
export { AuthService };
