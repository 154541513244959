import { HttpClientService } from '../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../config/evironment-config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../core/http/http-client.service";
import * as i2 from "../../config/evironment-config";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(httpClient, environmentConfiguration) {
        this.httpClient = httpClient;
        this.environmentConfiguration = environmentConfiguration;
        this.resetState = new Subject();
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.notificationsEndPoint = environmentConfiguration.get('notificationsEndPoint');
    }
    NotificationsService.prototype.getNewNotificationsCounter = function (dsaId) {
        return this.httpClient.get(this.baseApi + this.notificationsEndPoint.getNewNotificationsCounterConsumer + ("?dsaID=" + dsaId));
    };
    NotificationsService.prototype.getNotificationEvents = function (data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getNotificationEventsConsumer, data);
    };
    NotificationsService.prototype.resetNotificationsCounter = function (data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.resetNotificationsCounterConsumer, data);
    };
    NotificationsService.prototype.getDailyReports = function (data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportsConsumer, data);
    };
    NotificationsService.prototype.getDailyReportDocument = function (data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportDocumentConsumer, data);
    };
    NotificationsService.prototype.getVesselEventBLs = function (data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getVesselEventBLsConsumer, data);
    };
    NotificationsService.prototype.setResetState = function (state) {
        this.resetState.next(state);
    };
    NotificationsService.prototype.getResetState = function () {
        return this.resetState.asObservable();
    };
    NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.HttpClientService), i0.ɵɵinject(i2.EnvironmentConfiguration)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
