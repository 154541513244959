import { MsalService } from '@azure/msal-angular';
var MaintenanceGuard = /** @class */ (function () {
    function MaintenanceGuard(msalService) {
        this.msalService = msalService;
    }
    MaintenanceGuard.prototype.canActivate = function (route, state) {
        return !!this.msalService.getAccount();
    };
    return MaintenanceGuard;
}());
export { MaintenanceGuard };
