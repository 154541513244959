import { OnInit, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from 'app/config/config.sevice';
import { AuthService } from 'app/auth/auth.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../../shared/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentConfiguration } from '../../../config/evironment-config';
import { BroadcastService, MsalService } from '@azure/msal-angular';
var MaintanceErrorComponent = /** @class */ (function () {
    function MaintanceErrorComponent(configService, cookiesService, translateService, authService, router, loaderService, route, msalService, broadcastService, environmentConfig) {
        var _this = this;
        this.configService = configService;
        this.cookiesService = cookiesService;
        this.translateService = translateService;
        this.authService = authService;
        this.router = router;
        this.loaderService = loaderService;
        this.route = route;
        this.msalService = msalService;
        this.broadcastService = broadcastService;
        this.environmentConfig = environmentConfig;
        this.isDefaultLanguage = false;
        this.isTryAgain = false;
        this.envType = 'zim';
        this.msalCookieErrorText = '';
        this.isMsalError = false;
        this.envType = this.environmentConfig.get('type').toLowerCase();
        this.broadcastSubscription = this.broadcastService.subscribe('msal:acquireTokenFailure', function (payload) {
            if (payload.errorMessage.includes('AADB2C90077')) {
                if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') === -1 &&
                    navigator.userAgent.indexOf('FxiOS') === -1) {
                    _this.isMsalError = true;
                }
                else {
                    _this.msalService.logout();
                }
            }
        });
    }
    MaintanceErrorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParamMap.subscribe(function (queryParams) {
            var lang = _this.cookiesService.get('language') || 'en';
            _this.msg = JSON.parse(queryParams.get('msg'));
            _this.isTryAgain = queryParams.get('isTryAgain') === 'true';
            if (_this.authService.isAuthentificated()) {
                _this.translateService.use(lang).subscribe(function (data) {
                    _this.isDefaultLanguage = data instanceof HttpErrorResponse;
                    _this.loaderService.hideCoverAllSpinner();
                }, function () {
                    _this.isDefaultLanguage = true;
                    _this.loaderService.hideCoverAllSpinner();
                });
            }
            else {
                setTimeout(function () { return _this.loaderService.hideCoverAllSpinner(); }, 1000);
                _this.isDefaultLanguage = true;
            }
        });
    };
    MaintanceErrorComponent.prototype.ngOnDestroy = function () {
        this.broadcastSubscription.unsubscribe();
    };
    MaintanceErrorComponent.prototype.tryAgain = function () {
        this.router.toLogout();
    };
    return MaintanceErrorComponent;
}());
export { MaintanceErrorComponent };
