import * as i0 from "@angular/core";
var MobileService = /** @class */ (function () {
    function MobileService() {
    }
    MobileService.prototype.isMobile = function () {
        return window.innerWidth < 768;
    };
    MobileService.prototype.isTablet = function () {
        return window.innerWidth >= 768 && window.innerWidth < 992;
    };
    MobileService.prototype.isDevice = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    MobileService.prototype.isApple = function () {
        return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1);
    };
    MobileService.prototype.isIE = function () {
        return window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    };
    MobileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileService_Factory() { return new MobileService(); }, token: MobileService, providedIn: "root" });
    return MobileService;
}());
export { MobileService };
