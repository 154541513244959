import { CookieService } from 'ngx-cookie';
import { ConfigService } from 'app/config/config.sevice';
import { MsalService } from '@azure/msal-angular';
var UmbracoConfigGuard = /** @class */ (function () {
    function UmbracoConfigGuard(msalService, cookiesService, configService) {
        this.msalService = msalService;
        this.cookiesService = cookiesService;
        this.configService = configService;
    }
    UmbracoConfigGuard.prototype.canActivate = function (route, state) {
        var isAuthenticated = !!this.msalService.getAccount();
        if (isAuthenticated) {
            return this.configService.loadConfig();
        }
        return true;
    };
    return UmbracoConfigGuard;
}());
export { UmbracoConfigGuard };
