import { EnvironmentConfiguration } from 'app/config/evironment-config';
export function environmentInit(config) {
    return function () { return config.loadConfig(); };
}
/*----prototype----*/
// Select2Component.prototype.ngAfterViewInit = function () {
//     const that = this;
//     this.element = jQuery(this.selector.nativeElement);
//     this.initPlugin();
//     if (typeof this.value !== 'undefined') {
//         this.setElementValue(this.value);
//     }
//     this.element.on('select2:select', function () {
//         that.valueChanged.emit({
//             value: that.element.val()
//         });
//     });
//
//     this.element.on('select2:unselect', function () {
//         that.valueChanged.emit({
//             value: ''
//         });
//     });
//
// };
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
